import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import ImageCallout from 'components/layout/ImageCallout';
import CompareCaption from 'components/layout/CompareCaption';
import RoundButton from 'components/typography/RoundButton';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Callouts = () => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Root>
          <Wrapper margin>
            <ImageCallout
              image={require('src/assets/images/design/gryphon-house-appliances.jpg')}
              title={t.design.callouts[1].title}
              text={t.design.callouts[1].text}
              parallax={[-5, 5]}
              width={494}
              textWidth={locale === 'tc' ? 430 : 420}
              margin
            />
            <ImageCallout
              image={require('src/assets/images/design/gryphon-house-countertops.jpg')}
              title={t.design.callouts[2].title}
              text={t.design.callouts[2].text}
              parallax={[-20, 10]}
              width={495}
              textWidth={420}
              margin
            />
          </Wrapper>
          <Wrapper largeMargin>
            <ImageCallout
              image={require('src/assets/images/design/gryphon-house-bedroom.jpg')}
              title={t.design.callouts[3].title}
              text={t.design.callouts[3].text}
              parallax={[-5, 10]}
              width={700}
              textWidth={360}
              margin
            />
            <ImageCallout
              image={require('src/assets/images/design/gryphon-house-bedroom-window-view.jpg')}
              title={t.design.callouts[4].title}
              text={t.design.callouts[4].text}
              parallax={[-10, 5]}
              width={391}
              textWidth={260}
              margin
            />
          </Wrapper>
          {/* <CompareCaption
            images={[
              require('src/assets/images/residences/bathroom-scheme-dark.jpeg'),
              require('src/assets/images/residences/bathroom-scheme-light.jpeg'),
            ]}
            title={t.design.imageCompares.bathroom.title}
            text={t.design.imageCompares.bathroom.text}
            height={720}
          /> */}
          <Wrapper>
            <ImageCallout
              image={require('src/assets/images/residences/bathroom-scheme-dark.jpeg')}
              width={910}
              row
              title={t.design.imageCompares.bathroom.title}
              text={t.design.imageCompares.bathroom.text}
              textWidth={285}
              parallax={[-5, 5]}
              margin
            />
          </Wrapper>
          <Wrapper>
            <ImageCallout
              image={require('src/assets/images/design/gryphon-house-terraces.jpeg')}
              title={t.design.callouts[5].title}
              text={t.design.callouts[5].text}
              parallax={[-5, 5]}
              width={1220}
              textWidth={420}
              rightText
              margin
            />
          </Wrapper>
          <Wrapper>
            <ImageCallout
              image={require('src/assets/images/design/gryphon-house-rooftop.jpeg')}
              title={t.design.callouts[6].title}
              text={t.design.callouts[6].text}
              parallax={[-5, 5]}
              width={1220}
              textWidth={locale === 'tc' ? 410 : 420}
              rightText
              margin
            />
          </Wrapper>
          <Features href="/assets/pdf/feature_list.pdf" target="_blank">
            <RoundButton
              handleClick={(f) => f}
              transparent
              text={t.design.downloadFeatures}
            />
          </Features>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  margin: 0 0 ${vwMobile(80)};
  @media ${media.tablet} {
    margin: 0 0 ${vwTablet(80)};
  }
  @media ${media.desktop} {
    margin: ${vwDesktop(160)} 0;
  }
`;

const Wrapper = styled.div`
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    padding: 0 ${vwDesktop(60)};
    margin-bottom: ${(props) => (props.margin ? vwDesktop(120) : 0)};
    > div:nth-of-type(2) {
      margin-top: ${(props) => {
        if (props.largeMargin) {
          return vwDesktop(560);
        } else if (props.margin) {
          return vwDesktop(240);
        } else {
          return 0;
        }
      }};
    }
  }
`;

const Features = styled.a`
  display: block;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: ${vwMobile(80)};
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(160)};
  }
  @media ${media.desktop} {
    margin-top: ${vwDesktop(-80)};
    margin-bottom: ${vwDesktop(160)};
  }
`;

export default Callouts;
